@media only screen and (max-width: 1200px) {
  /*
   * STRUCTURE
   */
  .col {
  }
  .services {
    padding-left: 20px;
    padding-right: 20px;
  }
  .hide-on-desktop {
    display: block;
  }

  .iteme-button-group {
    clear: both;
  }

  /*
   * HEADER
   */
  .iteme-header {
    max-height: 70px !important;
    /*margin-bottom: 0px;*/
  }
  .iteme-header img {
    height: 60%;
  }

  .iteme-header a {
    padding: 0 !important;
    width: unset;
    display: contents;
  }
  .iteme-header-menu {
    display: block;
    position: fixed;
    padding-top: 40px;
    background-color: #555;
    top: 0;
    left: -100%;
    width: 100%;
    min-height: 100%;
    z-index: 1;
    color: white;
    /*max-height: calc(100vh - 210px);*/
    max-height: 100%;
    overflow-y: auto;
  }
  .iteme-header-menu a {
    display: inline-block !important;
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 24px;
  }
  .header-div {
    padding: 0;
  }
  .header-button {
    display: block;
    padding: 15px 30px;
  }
  .header-button:hover {
    background-color: unset;
  }
  .header-submenu {
    position: relative;
    display: block !important;
    margin-top: 15px;
    box-shadow: unset;
    border-bottom: 1px solid white;
    padding-bottom: 20px;
    background-color: unset;
  }
  .language-selector {
    outline: unset !important;
    background-color: unset !important;
    margin: 20px 40px;
  }
  .language-selector > img {
    display: none;
  }
  .language-selector img {
    height: 40px !important;
  }
  .language-selector ul {
    display: block;
    background-color: unset;
    border: unset;
  }
  .language-selector li {
    display: inline-block;
    margin-right: 20px;
  }

  /*
   * FOOTER
   */
  .iteme-footer {
    margin-top: 0;
  }

  .iteme-footer .col-4 {
    width: 100%;
    padding-top: 0;
  }

  .iteme-footer .col-2 {
    width: 50%;
    padding-top: 0;
  }

  .iteme-footer h4 {
    padding-top: 20px;
  }

  /*
   * FORM
   */
  .iteme-form {
    margin: 0;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 100px;
    background-color: #eee;
    width: 100% !important;
    overflow: auto;
  }

  .iteme-form row {
    margin-bottom: 0 !important;
  }
  .iteme-form input,
  .iteme-form select,
  .iteme-form textarea {
    margin-bottom: 20px;
  }
  .form-title {
    margin-bottom: 30px;
  }
  .basket-badge {
    margin-left: 13px;
    margin-top: -49px;
  }

  /*
   * TABLE
   */

  .iteme-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .iteme-table tr {
    display: block;
    margin-bottom: 20px;
    background-color: #eee;
    font-size: 18px;
    height: unset;
  }

  .iteme-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .iteme-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    /*text-transform: uppercase;*/
  }

  .iteme-table td:last-child {
    border-bottom: 0;
  }

  .iteme-table-mobile-left td {
    text-align: left !important;
  }

  /*
   * HOMEPAGE
   */
  .left-line-black,
  .right-line-black {
    width: 100px;
  }
  .section-black h2 {
    font-size: 30px;
  }
  .col {
    width: auto !important;
  }

  /*
    * DASHBOARD
    */
  .dashboard {
    grid-template-columns: auto;
    margin: 0;
  }
  .services-container {
    grid-template-columns: 33% 33% 33%;
  }
  .dashboard-notification {
    order: -1;
  }
  .dashboard-categories {
    display: none;
  }
  .dashboard-logo {
    max-height: unset;
  }
  .status-dashboard {
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  }
  .status-dashboard-card-value {
    font-size: 15px;
  }
  .dashboard-logo {
    max-width: 120px;
  }
  .service-search {
    padding: 4px;
  }
  .notifications {
    margin: 6px;
  }
}
