/* ===================================================================
 * fonts.scss
 *
 * ------------------------------------------------------------------- */


/*
 * montserrat
================================================================================ */

@font-face {
  font-family: "montserrat-black";
  src: url("./montserrat/montserrat-black-webfont.woff2") format("woff2"), url("./montserrat/montserrat-black-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-extrabold";
  src: url("./montserrat/montserrat-extrabold-webfont.woff2") format("woff2"), url("./montserrat/montserrat-extrabold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-bold";
  src: url("./montserrat/montserrat-bold-webfont.woff2") format("woff2"), url("./montserrat/montserrat-bold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-semibold";
  src: url("./montserrat/montserrat-semibold-webfont.woff2") format("woff2"), url("./montserrat/montserrat-semibold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-medium";
  src: url("./montserrat/montserrat-medium-webfont.woff2") format("woff2"), url("./montserrat/montserrat-medium-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-regular";
  src: url("./montserrat/montserrat-regular-webfont.woff2") format("woff2"), url("./montserrat/montserrat-regular-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-light";
  src: url("./montserrat/montserrat-light-webfont.woff2") format("woff2"), url("./montserrat/montserrat-light-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-extralight";
  src: url("./montserrat/montserrat-extralight-webfont.woff2") format("woff2"), url("./montserrat/montserrat-extralight-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "montserrat-thin";
  src: url("./montserrat/montserrat-thin-webfont.woff2") format("woff2"), url("./montserrat/montserrat-thin-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}


/*
 * Muli
================================================================================ */

@font-face {
  font-family: "muli-extrabold";
  src: url("./muli/muli-extrabold-webfont.woff2") format("woff2"), url("./muli/muli-extrabold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-extrabold-italic";
  src: url("./muli/muli-extrabolditalic-webfont.woff2") format("woff2"), url("./muli/muli-extrabolditalic-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-bold";
  src: url("./muli/muli-bold-webfont.woff2") format("woff2"), url("./muli/muli-bold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-bold-italic";
  src: url("./muli/muli-bolditalic-webfont.woff2") format("woff2"), url("./muli/muli-bolditalic-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-semibold";
  src: url("./muli/muli-semibold-webfont.woff2") format("woff2"), url("./muli/muli-semibold-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-semibold-italic";
  src: url("./muli/muli-semibolditalic-webfont.woff2") format("woff2"), url("./muli/muli-semibolditalic-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-regular";
  src: url("./muli/muli-regular-webfont.woff2") format("woff2"), url("./muli/muli-regular-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-italic";
  src: url("./muli/muli-italic-webfont.woff2") format("woff2"), url("./muli/muli-italic-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-light";
  src: url("./muli/muli-light-webfont.woff2") format("woff2"), url("./muli/muli-light-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-light-italic";
  src: url("./muli/muli-lightitalic-webfont.woff2") format("woff2"), url("./muli/muli-lightitalic-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-extralight";
  src: url("./muli/muli-extralight-webfont.woff2") format("woff2"), url("./muli/muli-extralight-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "muli-extralight-italic";
  src: url("./muli/muli-extralightitalic-webfont.woff2") format("woff2"), url("./muli/muli-extralightitalic-webfont.woff") format("woff");
  font-style: normal;
  font-weight: normal;
}
