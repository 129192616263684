.swl-policy {
    background-color: white;
    --bs-gutter-x: unset !important;
    padding: 20px;
    margin-bottom: 20px;
}

.swl-policy-button {
    font-size: 22px;
    background-color: #003e3e;
    color: #fff;
    width: 35px;
    height: 35px;
    text-align: center;
    cursor: pointer;
}

.swl-policy-amount {
    font-size: 22px;
}