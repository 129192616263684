/*

  z-indexes

  overlay                              1000
  go-top                                600
  Homepage-Header:                      501
  ("home-image-right")                  500
  ("about-how-content .step::before")   500
  ("left-line:before")                    2
  ("right-line:before")                   2
  ("left-line-black:before")              2
  ("right-line-black:before")             2

*/

/**********
 * GENERAL
 **********/
.password {
  font-family: text-security-disc;
}
.right {
  float: right;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.hide-on-desktop {
  display: none;
}

body {
  background-image: linear-gradient(to bottom right, #f5f5f5, #eee);
}

h3,
h4,
h5,
p {
  font-size: 17px !important;
  font-weight: 400;
}

a {
  text-decoration: none !important;
}

ul {
  padding-inline-start: 0;
  list-style-type: none;
}

/***********
 * HOMEPAGE
 ***********/

/***************
 * ITEME HEADER
 ***************/

/* .header-image {
  background-image: url("../images/RTN_Landing_page.jpg");
  background-size: cover;
} */

.iteme-header {
  background-color: #003e3e !important;
  color: #fff;
  padding: 0 50px !important;
  margin-bottom: 20px;
  box-shadow: 0px 1px 5px #333;
  height: 170px;
  transition: height 0.15s ease-out;
  justify-content: space-between !important;
}

.iteme-header-menu {
  display: contents;
  transition: all 0.2s ease-in-out;
}

.iteme-header > a {
  display: none;
}

.iteme-header img {
  height: 70%;
  max-height: 90px;
  cursor: pointer;
}

.header-button {
  display: flex;
  position: relative;
  height: 100%;
  padding: 0 55px;
  cursor: pointer;
  font-size: 18px;
  align-items: center;
}

.header-div {
  display: flex;
  position: relative;
  height: 100%;
  width: 120px;
  padding: 0 55px;
  font-size: 18px;
  align-items: center;
}

.header-button:hover {
  background-color: #ccc;
  color: #000 !important;
}

.header-submenu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 100%;
  background-color: #ccc;
  color: #000 !important;
  /* box-shadow: 2px 2px 2px #333; */
}

.header-button:hover .header-submenu {
  display: block;
}

.header-submenu-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  padding: 12px 20px;
}

.header-submenu-item:hover {
  background-color: #003e3e;
  color: #fff;
}

.header-submenu-icon {
  display: inline-block;
  width: 50px;
  text-align: center;
}

.header-submenu-label {
  display: inline-block;
  vertical-align: text-bottom;
}

.iteme-header a {
  display: none;
}

.iteme-header-menu a {
  display: none !important;
  position: absolute;
  top: 10px;
  right: 20px;
  display: inline-block;
  font-size: 24px;
}

/***************
 * ITEME FOOTER
 ***************/

.iteme-footer {
  padding: 20px;
  padding-top: 80px;
  margin-top: 80px;
  background-color: #333;
  color: #aaa;
}

.iteme-footer p {
  text-align: justify;
}

.iteme-footer .col-4,
.iteme-footer .col-2,
.iteme-footer .col-3 {
  padding: 30px;
}

.iteme-footer .col-12 {
  padding-top: 80px;
  padding-bottom: 30px;
  text-align: center;
}

.iteme-footer h4 {
  padding-bottom: 20px;
  padding-top: 50px;
}

.footer-logo {
  height: 80px;
  background: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 20px;
}

/********************
 * LANGUAGE SELECTOR
 ********************/

.language-selector {
  position: relative;
  white-space: nowrap;
}

.language-selector:hover {
  background-color: #eee;
  outline: 10px solid #eee;
}

.language-selector img {
  height: 20px;
  filter: grayscale(80%);
}

.language-selector img:hover {
  filter: grayscale(0%);
}

.language-selector:hover img {
  height: 25px;
}

.language-selector ul {
  cursor: pointer;
  position: absolute;
  display: none;
  top: 100%;
  left: -10px;
  padding: 0;
  background-color: #eee;
  border-color: #eee;
  border-style: solid;
  border-width: 0 10px 10px 10px;
}

.language-selector li {
  margin-top: 4px;
}

.language-selector:hover ul {
  display: inline-block;
}

/********************
 * DASHBOARD BUTTON
 ********************/

.dashboard-botton {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  width: 7vw;
  color: #555;
}

.dashboard-botton svg {
  transition: all 0.2s ease-in-out;
}

.dashboard-botton:hover {
  color: #555;
}

.dashboard-botton:hover svg {
  transform: rotate(15deg) scale(1.3);
}
.card-button {
  font-size: 20px;
  cursor: pointer;
  padding: 20px;
}
.card-button:hover {
  color: #003e3e !important;
}

/**************
 * ITEME FORM
 **************/

.iteme-form {
  opacity: 0.9;
  flex: 0 0 auto;
  width: 66.66666667%;
  margin-top: 100px;
  margin-left: 15%;
  background-color: #eee;
  padding: 50px;
  box-shadow: 1px 1px 1px #ccc;
  overflow: auto;
}
.form-control:focus{
  box-shadow: none !important;
  background-color: unset !important;
}
.date-input,
.iteme-registration-form input,
.iteme-registration-form select,
.iteme-form input,
.iteme-form select,
.iteme-form textarea {
  display: block;
  background-color: white;
  color: #555;
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  outline: none;
  border-radius: 0;
  font-size: 18px;
  padding: 0 15px;
  margin-bottom: 20px;
}
input[type="file"] {
  display: none;
}
.iteme-file-upload {
  background-color: #555;
  color: #eee !important;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
  height: 40px;
  text-align: left;
}
.iteme-registration-form {
  background-color: #eee;
  padding: 50px;
  box-shadow: 1px 1px 1px #ccc;
}

.languageselect {
  display: block;
  background-color: white;
  color: #555;
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  outline: none;
  border-radius: 0;
  font-size: 18px;
  padding: 0 15px;
  margin-bottom: 20px !important;
}

.iteme-form textarea {
  min-height: 150px;
  resize: none;
  padding: 20px;
}

.iteme-form input:focus,
.iteme-form select:focus,
.iteme-form textarea:focus {
  outline: 1px solid #003e3e;
  box-shadow: none;
}

.iteme-form input:disabled,
.iteme-form select:disabled,
.iteme-form textarea:disabled {
  cursor: default;
  background-color: #dfdfdf;
}
.disable {
  cursor: not-allowed;
}
.iteme-form label {
  color: #555;
}
/* label {
  color: #555;
} */
.form-title {
  font-size: 26px;
  font-weight: bold;
  color: #555;
  display: inline-block;
  margin-bottom: 50px;
}
.title {
  position: absolute;
  margin-top: 28px;
}
.title-icon {
  position: absolute;
  padding-left: 30px;
}

.form-logo {
  height: 97px;
  margin-right: 20px;
  vertical-align: baseline;
  filter: grayscale(100%);
}

.form-icon {
  color: red;
  width: 61px !important;
}
.icons {
  background: #fff;
  margin-bottom: 20px;
}
.icons svg {
  cursor: pointer;
  font-size: 20px;
}
.icons svg :hover {
  color: #c4f700;
}
.iteme-form .error {
  color: #a02c5a;
  font-size: 14px;
}
.error {
  color: #a02c5a;
  font-size: 14px;
  padding: 5px;
}

.iteme-form-header {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
  padding: 5px 0;
  background-color: #555;
  color: #ddd;
  text-align: center;
  font-weight: bold;
}

.total-amount {
  background-color: #ddd;
  border: 1px solid #555;
  color: #555;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: 20px;
}

.total-amount span {
  position: absolute;
  right: 20px;
}

/****************
 * ITEME BUTTON
 ****************/

.iteme-button-group {
  margin: 20px 0;
}

.iteme-button {
  background-color: #555;
  color: #eee;
  min-width: 40px;
  height: 40px;
  border-radius: 0;
  border: 1px solid #333;
  outline: 0;
  padding: 0 15px;
  margin: 0 5px 5px 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-family: "montserrat-regular", sans-serif;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
}
.iteme-button-align {
  display: flex;
  justify-content: space-between;
}
.kyc-remove {
  padding-left: 15px;
  font-size: 25px;
  cursor: pointer;
  color: #003e3e;
}
.kyc-save {
  padding-left: 15px;
  font-size: 30px;
  cursor: pointer;
  color: #2ca060;
}
.iteme-button:disabled,
.iteme-pagination-button:disabled {
  background-color: #ccc;
  color: #eee;
  border: 1px solid #aaa;
  cursor: not-allowed;
}
.disable {
  background-color: #ccc;
  color: #eee;
  border: 1px solid #aaa;
  cursor: not-allowed;
}
.login-button {
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid #fff;
  color: white;
  padding: 10px 50px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.contact-button {
  height: 40px;
  padding: 10px 50px;
  background-color: rgba(100, 100, 100, 0.2);
  border: 1px solid rgba(100, 100, 100, 0.2);
  cursor: pointer;
}
.table-btn {
  color: #eee !important;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  --bs-text-opacity: 1;
}
.iteme-table td svg {
  font-size: 25px;
}
.bg-green {
  background-color: #484;
}
.bg-red {
  background-color: #8b0000;
}
.table-btn:hover {
  color: #000;
}
.sort-btn {
  cursor: pointer;
  padding-left: 2px;
  /*font-size: 20px;*/
}
.sort-btn:hover {
  color: #003e3e;
}
.iteme-button[disabled]:hover,
.iteme-pagination-button[disabled]:disabled {
  background-color: #ccc;
  color: #eee;
  border: 1px solid #aaa;
  cursor: not-allowed;
}

.iteme-button:hover,
.contact-button:hover,
.iteme-pagination-button:hover {
  background-color: #eee;
  color: #003e3e;
  border: 1px solid #003e3e;
}

.login-button:hover {
  background-color: white;
  color: #333;
}
/***************
 * ITEME-TABLE
 ***************/

.iteme-table {
  width: 100%;
  font-family: "montserrat", sans-serif;
  margin-bottom: 20px;
  padding: 0;
}

.iteme-table th {
  color: #151515;
  font-family: "montserrat-bold", sans-serif;
  position: relative;
}

.iteme-table th,
.iteme-table td {
  padding: 5px;
  vertical-align: middle;
}

.iteme-table tr {
  height: 50px;
}

.list .iteme-table tr:nth-child(odd) {
  background-color: #eee;
}

.list .iteme-table tr:nth-child(even) {
  background-color: #ccc;
}

.iteme-pagination-button {
  background-color: #555;
  color: #eee;
  min-width: 40px;
  height: 40px;
  border: 1px solid #555;
  margin-right: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.iteme-pagination-dots {
  margin-left: 5px;
  margin-right: 10px;
  font-size: 24px;
}

.child {
  position: absolute;
  right: 0;
  top: 100%;
  min-width: 100%;
  background-color: #555;
  color: #fff9f9 !important;
  min-height: 40px;
}
.rdrDateRangePickerWrapper {
  position: absolute;
  right: 0;
  z-index: 20;
}
.child-item {
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  padding: 12px 20px;
  background-color: #555;
  cursor: pointer;
}

.child-item:hover {
  background-color: #eeeeee;
  color: #333;
}

.search-item:hover .child-submenu {
  display: block;
}

.child-submenu {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  width: 100%;
  min-width: 250px;
  border: 5px solid #555;
}

.search-item:hover {
  position: relative;
  background-color: #555;
  color: #eee;
  padding: 10px 20px;
}

.heading-title {
  font-size: 35px;
}

.parent {
  display: flex;
  position: relative;
  height: 40px;
  padding: 0 55px;
  cursor: pointer;
  font-size: 18px;
  align-items: center;
  background-color: #555;
  color: #eee;
  z-index: 10;
}

.search {
  background-color: unset;
}

.parent :hover .search {
  background-color: #555;
  color: #eee !important;
}

.search-filters {
  width: 100%;
  clear: both;
  padding: 10px 0;
}

.search-filter {
  background-color: #555;
  color: #eee;
  padding: 5px;
  text-align: left;
}

.search-filter .close {
  cursor: pointer;
  color: #fff;
  margin-left: 20px;
  padding: 0 4px;
}
.search-filter .close:hover {
  background-color: #eee;
  color: #555;
}

.table-search-popup {
  position: absolute;
  background-color: #555;
  color: #eee;
  min-width: 150px;
  min-height: 80px;
  top: 0;
  display: none;
  box-shadow: 2px 2px 2px #333;
  padding: 10px 10px 0 10px;
  z-index: 10;
}

.table-search-button {
  margin-left: 5px;
  cursor: pointer;
  padding: 2px;
}

.table-search-button:hover {
  background-color: #555;
  color: #eee;
}
.active {
  background-color: #fff;
  color: #333;
}
.table-search-popup-button {
  cursor: pointer;
  padding: 2px 8px;
}

.table-search-popup-button:hover {
  background-color: #eee;
  color: #555;
}

.table-search {
  width: 100%;
  padding: 10px 0px;
  /*padding-left: 50px;*/
  background-color: #555;
  color: #eee;
  border: none;
  /*border-bottom: 1px solid #eee;*/
  outline: none;
  margin-top: 10px;
}

.table-search-input {
  background-color: #555;
  color: #eee;
  border: none;
  outline: none;
  border-bottom: 1px solid #eee;
  width: 100%;
  padding-left: 35px;
}

.table-search-icon {
  position: absolute;
  top: 55px;
  left: 15px;
}

/* .iteme-table th:hover .table-search-popup {
  display: block;
} */
.nav-tabs .nav-link {
  color: #003e3e;
}
.nav-tabs .nav-link:hover {
  color: #555;
}
/*******************
 * HOMEPAGE_HEADER
 *******************/

.header {
  width: 100%;
  position: absolute;
  height: 170px;
  z-index: 501;
  background-color: transparent !important;
}

.header-logo {
  position: absolute;
  left: 21px;
  top: 50px;
}

.header-logo img {
  width: 180px;
}

.header-nav {
  font-family: "montserrat-bold", sans-serif;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 40px;
}

.header-nav li {
  display: inline-block;
  margin-right: 1.5rem;
}

/****************
 * LOAD SPINNER
 ****************/

.lds-roller {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  /* margin-top: -40px;
  margin-left: -40px; */
  padding-right: 80px;
  padding-bottom: 80px;
  background: rgba(0, 30, 30, .7);
}

.lds-roller div {
  animation: lds-roller 1.7s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  /* background: #a02c5a; */
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/****************
 * LOAD SPINNER CARD
 ****************/
.lds-spinner {
  /* color: official; */
  color: #003e3e;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  /* background: #a02c5a; */
  background: #003e3e;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*********
 * MODAL
 *********/

.modal-content input,
.modal-content span {
  border-radius: 0 !important;
  /* border: 1px solid #555; */
}

.modal-content .input-group-text {
  background-color: #555;
  color: #eee;
  border: 1px solid #555;
  font-size: 16px !important;
}

/************************
 * OVERRIDING REACT CSS
 ************************/

.modal-content {
  border-radius: 0 !important;
}
a,
a:hover {
  color: unset !important;
}

/*

 LEGACY CSS FROM HERE ON
 TODO REPLACE STEP BY STEP

*/

/* ===================================================================
 *  10. home - (_site-layout.scss)
 *
 * ------------------------------------------------------------------- */

#home {
  width: 100%;
  min-height: 800px;
  background-color: transparent;
  position: relative;
}

#home .shadow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background: -moz-linear-gradient(left, black 0%, black 20%, transparent 100%);
  background: -webkit-linear-gradient(
    left,
    black 0%,
    black 20%,
    transparent 100%
  );
  background: linear-gradient(to right, black 0%, black 20%, transparent 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000', GradientType=1);
}

#home .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.2;
  background-color: #000000;
}

.no-js #home {
  background: #151515;
}

.home-content {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.home-content .contents {
  position: relative;
}

@media only screen and (min-width: 900px) {
  .home-content .container {
    width: 100%;
    max-width: 1170px;
  }
}

.home-content-left {
  padding: 15rem 0 12rem 40px;
  position: relative;
  left: 40%;
  top: 50px;
}

.home-content-left h3 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.6rem;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 2.4rem;
}

.home-content-left h1 {
  font-family: "montserrat-regular", sans-serif;
  font-size: 44px;
  letter-spacing: -0.05rem;
  line-height: 1.269;
  color: #ffffff;
  margin-bottom: 100px;
  text-shadow: 3px 3px 10px #333;
}

.home-content-left .button.stroke {
  color: #ffffff !important;
  border-color: #ffffff;
  margin: 0 0.6rem 1.2rem;
  padding: 0 2.2rem;
  min-width: 188px;
}

.home-content-left .button.stroke.student {
  color: #ffffff !important;
  border-color: #ffffff;
  margin: 0 0.6rem 1.2rem;
  padding: 0 2.2rem;
  min-width: 397px;
}

.home-content-left .button.stroke span[class*="icon"] {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.home-content-left .button.stroke:hover,
.home-content-left .button.stroke:focus {
  background-color: white !important;
  color: #000000 !important;
}
.kyc-image {
  width: 200px;
  height: 200px;
}
.home-image-right {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 21rem;
  z-index: 500;
  width: 50%;
  text-align: right;
}

.home-image-right img {
  vertical-align: bottom;
  width: 75%;
}

.home-social-list {
  position: absolute;
  right: 42px;
  top: 50%;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 2.2rem;
  line-height: 1.75;
  text-align: center;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
}

.home-social-list::before {
  display: block;
  content: "";
  width: 2px;
  height: 42px;
  background-color: rgba(255, 255, 255, 0.15);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

.home-social-list li {
  padding-left: 0;
}

.home-social-list li a,
.home-social-list li a:visited {
  color: #ffffff;
}

.fa-facebook-square:hover,
.fa-facebook-square:focus,
.fa-facebook-square:active {
  color: #3b5998;
}

.fa-twitter:hover,
.fa-twitter:focus,
.fa-twitter:active {
  color: #00b6f1;
}

.fa-linkedin:hover,
.fa-linkedin:focus,
.fa-linkedin:active {
  color: #04669a;
}

.fa-google-plus:hover,
.fa-google-plus:focus,
.fa-google-plus:active {
  color: #c20806;
}

.home-scrolldown {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  float: right;
}

.home-scrolldown i {
  padding-left: 9px;
}

.home-scrolldown a:hover,
.home-scrolldown a:focus {
  color: #44c455 !important;
}

html[data-useragent*="MSIE 10.0"] .home-scrolldown,
.oldie .home-scrolldown {
  display: none;
}

.scroll-icon {
  display: inline-block;
  font-family: "montserrat-medium", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  color: #ffffff !important;
  background: transparent;
  position: relative;
  top: 36px;
  right: 42px;
}

.scroll-icon i {
  font-size: 2.4rem;
  position: relative;
  bottom: -6px;
}

@-webkit-keyframes animate-it {
  0%,
  60%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }
  0%,
  60%,
  80%,
  100% {
    transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-5px);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    -webkit-transform: translateX(20px);
  }
  40% {
    transform: translateX(20px);
  }
}

@keyframes animate-it {
  0%,
  60%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }
  0%,
  60%,
  80%,
  100% {
    transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-5px);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    -webkit-transform: translateX(20px);
  }
  40% {
    transform: translateX(20px);
  }
}

@media only screen and (max-width: 1299px) {
  .home-image-right {
    right: 5rem;
  }
}

@media only screen and (max-width: 1199px) {
  #home {
    overflow: hidden;
  }
  .home-content {
    vertical-align: middle;
  }
  .home-content-left {
    padding: 18rem 0 12rem 40px;
  }
  .home-content-left h3 {
    font-size: 1.5rem;
  }
  .home-content-left h1 {
    font-size: 4.8rem;
  }
  .home-image-right {
    padding-top: 18rem;
  }
  .home-image-right img {
    vertical-align: top;
    width: 70%;
  }
}

@media only screen and (max-width: 1099px) {
  .home-content-left h1 {
    font-size: 4.6rem;
  }
  .home-content-left h1 br {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .home-content-left {
    width: 60%;
    padding: 18rem 6rem 12rem 4rem;
  }
  .home-content-left h3 {
    font-size: 1.5rem;
  }
  .home-content-left h1 {
    font-size: 4.2rem;
  }
  .home-image-right {
    width: 40%;
    padding-top: 120px;
  }
  .home-image-right img {
    vertical-align: top;
    width: 100%;
  }
}

@media only screen and (max-width: 899px) {
  #home {
    height: auto;
    min-height: 840px;
  }
  #home,
  .home-content {
    display: block;
    text-align: center;
  }
  .home-content-left {
    width: 94%;
    position: static;
    padding: 15rem 6rem 366px;
    margin: 0 auto;
  }
  .home-content-left h1 {
    margin-bottom: 3.6rem;
  }
  .home-image-right {
    width: 400px;
    right: auto;
    top: auto;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0;
    margin-bottom: -378px;
  }
}

@media only screen and (max-width: 767px) {
  #home {
    min-height: auto;
  }
  .home-content-left h3 {
    font-size: 1.4rem;
  }
  .home-content-left h1 {
    font-size: 3.6rem;
  }
}

@media only screen and (max-width: 599px) {
  .home-content-left {
    padding: 12rem 6rem 366px;
  }
  .home-content-left h1 {
    font-size: 3.3rem;
  }
  .home-content-left .button.stroke {
    width: 100%;
    min-width: 0;
    max-width: 280px;
  }
  .home-content-left .button.stroke.student {
    width: 100%;
    min-width: 0;
    max-width: 280px;
  }
  .home-social-list {
    font-size: 2rem;
    right: 30px;
  }
  .scroll-icon {
    top: 21px;
  }
}

@media only screen and (max-width: 499px) {
  .home-social-list,
  .home-scrolldown {
    display: none;
  }
  .home-content-left {
    padding: 12rem 2.5rem 366px;
    width: 100%;
  }
  .home-content-left h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 399px) {
  .home-content-left {
    padding: 12rem 0 300px;
  }
  .home-content-left .button.stroke {
    margin: 0 0 1.2rem 0;
  }
  .home-image-right {
    width: 320px;
    margin-bottom: -298px;
  }
}

/* ===================================================================
 *  11. about - (_layout.scss)
 *
 * ------------------------------------------------------------------- */

.home-section {
  background: #ffff;
  overflow: hidden;
}

#services {
  background: #ffff;
  padding-top: 8rem;
  overflow: hidden;
}

#advantages {
  background: #ffff;
  padding-top: 8rem;
  overflow: hidden;
}

#testmony {
  background: #ffff;
  padding-top: 8rem;
  overflow: hidden;
}

#about-iteme {
  min-height: 786px;
  background: #ffffff;
  padding-top: 16rem;
  overflow: hidden;
}

@media only screen and (min-width: 900px) {
  #about-iteme .container {
    width: 94%;
    max-width: 1170px;
  }
}

.about-statement {
  font-size: 1.6rem;
  padding-top: 25px;
  color: #767676;
  padding-bottom: 10px;
}

#jobs-iteme {
  min-height: 786px;
  background: #ffffff;
  padding-top: 16rem;
  overflow: hidden;
}

.job-statement {
  font-size: 1.6rem;
}

.about-intro {
  padding-bottom: 2.5rem;
}

.about-intro.row {
  max-width: 100%;
  margin: auto;
  margin-left: -15px;
  margin-right: -15px;
}

.services {
  padding-left: 60px;
  padding-right: 60px;
}

.list {
  padding-left: 20px;
  padding-right: 20px;
}

.about-features .features-list {
  padding-top: 2rem;
}

.about-features .feature {
  margin-bottom: 1.8rem;
}

.about-features .feature .icon {
  margin-bottom: 1.5rem;
  display: inline-block;
}

.about-features .feature .icon i {
  color: #39b54a;
  font-size: 4.8rem;
}

.about-features .feature h3 {
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.9rem;
  color: #151515;
  margin-bottom: 3rem;
}

.about-how {
  padding-top: 7.2rem;
  padding-bottom: 6rem;
}

.about-how h1.intro-header {
  text-align: center;
}

#about .about-how h1.intro-header::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.about-how-content {
  position: relative;
  width: 100%;
  max-width: 1000px;
  min-height: 300px;
  margin-top: 7.2rem;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}

.about-how-content::before,
.about-how-content::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
}

.about-how-content::before {
  left: 0;
}

.about-how-content::after {
  left: 50%;
}

.about-how-content .about-how-steps {
  width: 100%;
}

.about-how-content .step {
  padding: 0 40px 1.5rem 60px;
  position: relative;
  min-height: 240px;
}

.about-how-content .step h3 {
  margin-bottom: 2.4rem;
}

.about-how-content .step::before {
  display: block;
  font-family: "muli-bold";
  font-size: 2rem;
  content: attr(data-item);
  color: #ffffff;
  height: 54px;
  width: 54px;
  line-height: 54px;
  text-align: center;
  border-radius: 50%;
  background-color: #39b54a;
  box-shadow: 0 0 0 15px #ffffff;
  z-index: 500;
  position: absolute;
  top: -12px;
  left: -27px;
}

.about-bottom-image img {
  vertical-align: bottom;
}

@media only screen and (max-width: 1199px) {
  .about-how-content {
    max-width: 800px;
  }
}

@media only screen and (max-width: 991px) {
  .service-name {
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .service-image img {
    max-width: 50%;
  }
  .service-icon {
    font-size: 31px;
    margin: 6px 0px 12px 0px;
  }
}

@media only screen and (max-width: 375px) {
  .service-image img {
    max-width: 25%;
    width: 60px;
  }
  .service-icon {
    font-size: 31px;
    margin: 6px 0px 12px 0px;
  }
}

@media only screen and (max-width: 1023px) {
  .about-intro h1::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .about-intro [class*="col-"] {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
  }
  .about-how-content {
    max-width: 700px;
  }
}

@media only screen and (max-width: 849px) {
  .about-how-content {
    max-width: 550px;
  }
  .about-how-content::after {
    display: none;
  }
  .about-how-content .step {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
    padding-right: 18px;
    min-height: auto;
    padding: 0 40px 0.9rem 60px;
  }
}

@media only screen and (max-width: 767px) {
  .about-features .feature .icon i {
    font-size: 4.4rem;
  }
  .about-features .feature h3 {
    font-size: 1.8rem;
  }
  .about-how h3 {
    font-size: 1.9rem;
  }
  .about-how-content::before {
    left: 30px;
  }
  .about-how-content .step {
    padding: 0 10px 0 80px;
  }
  .about-how-content .step::before {
    font-size: 1.6rem;
    height: 45px;
    width: 45px;
    line-height: 45px;
    top: -9px;
    left: 7.5px;
  }
}

@media only screen and (max-width: 649px) {
  .about-features {
    max-width: 600px;
    text-align: center;
  }
  .about-features .feature {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 4.5rem;
  }
  .about-features .feature h3 {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 399px) {
  .about-how-content {
    text-align: center;
  }
  .about-how-content::before {
    display: none;
  }
  .about-how-content .step {
    padding: 60px 0 0 0;
  }
  .about-how-content .step::before {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

#testimonials {
  background: #ffffff;
  padding: 4rem 0 4rem;
  text-align: center;
  min-height: 600px;
}

#testimonials h1::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

#testimonials .owl-wrap {
  width: 80%;
  max-width: 900px;
  margin: 4.2rem auto 0;
}

#testimonial-slider {
  margin: 0 0 3rem 0;
  position: relative;
}

#testimonial-slider .slides {
  padding-bottom: 3rem;
}

#testimonial-slider p {
  font-family: "muli-regular", sans-serif;
  font-size: 1.8rem;
  line-height: 1.888;
  color: #303030;
}

#testimonial-slider p::before,
#testimonial-slider p::after {
  display: inline-block;
  font-family: "muli-regular", serif;
  font-size: 1.8rem;
  line-height: 1.888;
  position: relative;
  top: 0.3rem;
}

#testimonial-slider p::before {
  content: "\201C";
  font-size: 70px;
  font-weight: bolder;
  vertical-align: bottom;
  margin-bottom: -25px;
}

#testimonial-slider p::after {
  content: "\201D";
  font-size: 70px;
  font-weight: bolder;
  vertical-align: top;
}

#testimonial-slider .testimonial-author {
  margin-top: 4.2rem;
}

#testimonial-slider .testimonial-author .author-info {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-family: "montserrat-semibold", sans-serif;
  font-size: 1.9rem;
  margin-top: 0.9rem;
  margin-bottom: 3rem;
  color: #151515;
}

#testimonial-slider .testimonial-author .author-info span {
  display: block;
  font-family: "muli-bold", sans-serif;
  font-size: 1.6rem;
  line-height: 1.313;
  letter-spacing: 0;
  text-transform: none;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0.3rem;
}

#testimonial-slider .owl-nav {
  margin-top: 1.8rem;
  margin-bottom: 1.5rem;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

#testimonial-slider .owl-nav [class*="owl-"] {
  color: #ffffff;
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.4rem;
  height: 3rem;
  line-height: 3rem;
  margin: 0.6rem;
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.5);
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#testimonial-slider .owl-nav [class*="owl-"]:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  text-decoration: none;
}

#testimonial-slider .owl-nav .disabled {
  background: rgba(0, 0, 0, 0.2);
  cursor: default;
}

#testimonial-slider .owl-nav.disabled + .owl-dots {
  margin-top: 2px;
}

#testimonial-slider .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.details .owl-dots {
  text-align: left !important;
  -webkit-tap-highlight-color: transparent;
  padding: 10px;
}
#testimonial-slider .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  /* *display: inline; */
}

#testimonial-slider .owl-dots .owl-dot span {
  width: 9px;
  height: 9px;
  background-color: #959595;
  margin: 9px 9px;
  display: block;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#testimonial-slider .owl-dots .owl-dot.active span,
#testimonial-slider .owl-dots .owl-dot:hover span {
  background-color: #39b54a;
}

@media only screen and (max-width: 1023px) {
  #testimonial-slider p {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 767px) {
  #testimonial-slider p {
    font-size: 1.8rem;
  }
  #testimonial-slider .testimonial-author .author-info {
    font-size: 1.8rem;
  }
  #testimonial-slider .testimonial-author .author-info span {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 599px) {
  #testimonials .owl-wrap {
    width: 90%;
  }
  #testimonial-slider p {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 399px) {
  #testimonials .owl-wrap {
    width: 100%;
  }
  #testimonial-slider p {
    font-size: 1.8;
  }
}

#go-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 600;
  display: none;
}

#go-top a,
#go-top a:visited {
  text-decoration: none;
  border: 0 none;
  display: block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  background: #494949;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#go-top a i,
#go-top a:visited i {
  font-size: 18px;
  line-height: inherit;
}

#go-top a:hover,
#go-top a:focus {
  color: #ffffff;
}

@media only screen and (max-width: 1023px) {
  .footer-main > .container {
    max-width: 800px;
  }
  .footer-info,
  .footer-contact,
  .footer-site-links {
    margin-bottom: 1.2rem;
  }
  .footer-subscribe {
    clear: both;
  }
}

@media only screen and (max-width: 767px) {
  .footer-main > .container {
    max-width: 600px;
  }
  .footer-logo {
    margin-left: auto;
    margin-right: auto;
  }
  .footer-info {
    text-align: center;
  }
  #go-top {
    bottom: 0;
    right: 0;
  }
  #go-top a,
  #go-top a:visited {
    border-radius: 5px 0 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .footer-main .container {
    max-width: 480px;
  }
  .footer-main h4 {
    padding-bottom: 0;
    margin-bottom: 2.4rem;
  }
  .footer-main h4::before {
    display: none;
  }
  .footer-main ul.list-links {
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-main ul.list-links li a {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.03);
    padding: 0.6rem 0;
  }
  .footer-main ul.list-links li:first-child a {
    border-top: 1px solid rgba(255, 255, 255, 0.03);
  }
  .footer-contact,
  .footer-site-links,
  .footer-subscribe {
    text-align: center;
  }
  .footer-bottom {
    padding-bottom: 0.6rem;
  }
  .footer-bottom .copyright span {
    display: block;
  }
  .footer-bottom .copyright span::after {
    display: none;
  }
  .col-advantages {
    display: block;
  }
}

.iteme-modal-header {
  padding: 10px;
  text-align: end;
}

.iteme-modal-header svg {
  cursor: pointer;
}

.iteme-modal-header svg:hover {
  color: #003e3e;
}
.add-user-title {
  font-size: 20px;
  float: right;
}
.section-title h2 {
  font-size: 50px;
  letter-spacing: 0px;
  margin-top: 0px;
  font-family: initial;
  font-weight: 400;
  color: #fff;
}

.section h2 {
  font-size: 40px;
  letter-spacing: 0px;
  margin-top: 0px;
  font-weight: 400;
  color: #fff;
}

.section-black h2 {
  font-size: 40px;
  letter-spacing: 0px;
  margin-top: 0px;
  font-weight: 400;
  color: #000 !important;
  margin-bottom: 3.4rem !important;
  margin-top: 3.4rem;
}

.left-line {
  display: inline-block;
  height: 1px;
  line-height: 1px;
  margin-right: 20px;
  margin-bottom: 16px;
  position: relative;
  width: 150px;
  background: #dd3b2f;
}

.left-line:before {
  border: 1px #dd3b2f;
  border-radius: 15px;
  background: #dd3b2f;
  bottom: -6px;
  content: "";
  display: inline-block;
  height: 15px;
  margin: auto;
  overflow: auto;
  position: absolute;
  right: 0;
  width: 15px;
  z-index: 2;
}

.right-line {
  background: #dd3b2f;
  display: inline-block;
  height: 1px;
  line-height: 1px;
  margin-left: 20px;
  margin-bottom: 16px;
  position: relative;
  width: 150px;
}

.right-line:before {
  border: 1px #dd3b2f;
  border-radius: 15px;
  background: #dd3b2f;
  bottom: -6px;
  content: "";
  display: inline-block;
  height: 15px;
  left: 0;
  margin: auto;
  overflow: auto;
  position: absolute;
  width: 15px;
  z-index: 2;
}

.section-title h4 {
  margin-bottom: 2px;
  font-size: 25px;
  letter-spacing: 0px;
  font-weight: 400;
}

.text-prime {
  color: #fff;
}

.head-padding {
  font-size: 4rem !important;
  margin-top: 70px !important;
  color: #346b9c !important;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
}

.left-line-black {
  display: inline-block;
  height: 1px;
  line-height: 1px;
  margin-right: 20px;
  margin-bottom: 16px;
  position: relative;
  width: 150px;
  background: #003e3e;
}

.left-line-black:before {
  border: 1px #003e3e;
  border-radius: 15px;
  background: #003e3e;
  bottom: -7px;
  content: "";
  display: inline-block;
  height: 15px;
  margin: auto;
  overflow: auto;
  position: absolute;
  right: 0;
  width: 15px;
  z-index: 2;
}

.right-line-black {
  background: #003e3e;
  display: inline-block;
  height: 1px;
  line-height: 1px;
  margin-left: 20px;
  margin-bottom: 16px;
  position: relative;
  width: 150px;
}

.right-line-black:before {
  border: 1px #003e3e;
  border-radius: 15px;
  background: #003e3e;
  bottom: -7px;
  content: "";
  display: inline-block;
  height: 15px;
  left: 0;
  margin: auto;
  overflow: auto;
  position: absolute;
  width: 15px;
  z-index: 2;
}
.text-center {
  text-align: center;
}

.p-top-30 {
  padding-top: 30px;
}

.half-txt {
  width: 60%;
  margin: 0 auto;
  display: inline-block;
  line-height: 25px;
  color: #7e7e7e;
}

.text-uppercase {
  text-transform: uppercase;
}

.iteme-head {
  background-color: #494949 !important;
}

#header-nav-wrap {
  background-color: transparent !important;
}

.iteme-background {
  background-color: #ffffff !important;
}

.belticon {
  font-size: 37px !important;
  margin: 7px 0px 13px 0px;
  color: #003e3e;
}

.belticon-top {
  font-size: 36px;
  margin: 0px 0px;
  color: #003e3e;
}

.service-icon {
  font-size: 27px;
  margin: 6px 0px 4px 0px;
}

.btn-iteme-sm,
.btn-iteme-sm:hover,
.btn-iteme-sm:focus,
.btn-iteme-sm:active {
  font-size: 10px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  background-color: #003e3e;
  outline: none;
  padding: 8px 22px 8px 22px !important;
  text-align: center;
  height: 35px !important;
}

.data-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-container {
  padding: 1rem;
  width: 100%;
}

.value {
  font-weight: bolder;
}

.wallet-box {
  background-color: #003e3e !important;
}

.wallet-button {
  background-color: #ffffff !important;
  color: #003e3e !important;
}
.walletTitle {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}

.section {
  padding: 0 2.5rem;
}

.footer-contact h1 {
  color: #fff;
  font-size: 4rem;
}

.scrollLink {
  cursor: pointer;
}

.textInput {
  background-color: white !important;
  border: 1px solid #ccc !important;
}

.contact-label {
  color: #ccc;
}

.select-header {
  height: 40px;
  border: none;
  padding: 10px;
  background-color: rgba(100, 100, 100, 0.2);
  cursor: pointer;
}

.text {
  text-align: justify;
  text-justify: inter-word;
  color: #000 !important;
}
.name-text {
  color: #000 !important;
  font-size: medium !important;
  text-align: center !important;
  padding-top: 10px;
}
.testimony-image {
  border-radius: 50%;
  width: 55.33333% !important;
  display: block;
  margin: auto;
  padding-top: 15px !important;
}
.scrollDown {
  position: absolute;
  cursor: pointer;
  color: #fff;
  margin-top: 60px !important;
  left: 0%;
  margin-left: -10px;
}

.contact-logo {
  height: 5rem;
  position: fixed;
}
.contact-close {
  float: right;
  height: 5rem;
  position: fixed;
}

.loginTitle {
  text-align: center;
  margin-bottom: 10px;
}
.inputIcon {
  height: 100%;
}
.search-input {
  display: none;
}
.parent:hover .search-input {
  display: block;
}

.input:focus {
  height: 100%;
  box-shadow: unset !important;
}
.input {
  height: 100% !important;
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
}
.inputGroup {
  margin-bottom: 15px;
  height: 40px;
}
.link {
  color: #003e3e;
  cursor: pointer;

  padding: 10px;
}

.hide {
  display: none;
}
.overlay__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10000;
}
.short-overlay {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  background-color: rgb(0, 0, 0, 0.3);
  height: 50%;
  top: 50%;
}
.navbar {
  padding: 60px;
  display: flex;
}
.textLink {
  color: #000 !important;
  font-size: 20px;
}
.userIcon {
  border: 0.1px solid #fff;
  border-radius: 50%;
}
.me-custom {
  margin: 0 auto;
}

.shopLogo {
  height: 45px;
  max-width: 150px;
  margin-right: 10px;
  vertical-align: baseline;
  font-size: 43px;
}
.shopName {
  font-weight: 400;
  color: #000;
}

.service-name {
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  padding-left: 20px;
  margin-bottom: 20px;
  margin-top: 50px;
}
.dashboard-logo {
  max-height: 5vw;
  max-width: 200px;
  margin-bottom: 5px;
}
.dashboard-icon {
  font-size: 60px;
  color: #555;
  margin-bottom: 10px;
}
.dashboard {
  display: grid;
  grid-template-columns: 10% auto 30%;
  margin: 80px 32px 80px 0;
}
.dashboard-row {
  padding-left: 20px;
}
.dashboard-service {
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1.1;
  cursor: pointer;
  box-shadow: 0px 0px 10px #ddd;
  filter: grayscale(33%);
}
.dashboard-service-offline,
.dashboard-service-offline:hover {
  cursor: not-allowed !important;
  filter: grayscale(100%) !important;
  background-color: #eee !important;
}
.dashboard-service span p {
  font-size: 16px !important;
  font-weight: lighter;
  color: #333;
}
.dashboard-service span {
  text-align: center;
  pointer-events: none;
}
.dashboard-service span svg {
  font-size: 9px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.dashboard-service:hover {
  border: 1px solid #ddd;
  background-color: #fffa;
  filter: grayscale(0%);
}

.dashboard-service-dragged {
  border: 5px dotted #ddd;
}
.dashboard-favorite-dragover {
  border: 5px dotted #003e3e;
}
.dashboard-categories {
  margin-top: 63px;
  padding: 10px 10px 0 10px;
  z-index: 10;
  display: flex;
  justify-content: center;
}
.dashboard-categories-dragover {
  outline: 3px dotted #003e3e;
  background-color: #ccc !important;
}
.dashboard-category {
  background-color: #ccc;
  color: #333;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  margin: 30px;
  font-size: 24px;
  cursor: pointer;
  transition: background, color 0.2s ease-out;
  box-shadow: 0px 0px 4px #555;
}
.dashboard-category:hover {
  background-color: #003e3e;
  color: #c4f600;
}
.dashboard-category-active {
  background-color: #003e3e;
  color: #c4f600;
}
.dashboard-category span {
  pointer-events: none;
}
.dashboard-notification {
  height: 70%;
  background-color: #dedede;
  margin-top: 63px;
  padding: 10px 10px 0 10px;
  margin-left: 40px;
  border-top: 7px solid #003e3e;
}
.dashboard-notification h3 {
  text-align: center;
  font-family: "Comfortaa";
  font-size: 20px !important;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0 0 15px;
  padding: 0 0 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.item-center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications {
  margin-top: 71px;
  margin-left: 20px;
}

.notification svg {
  font-size: 30px;
  color: #003e3e;
  float: right;
  margin: 5px;
}
.notification h4 {
  font-size: 22px !important;
}
.notification span {
  font-size: 19px !important;
  white-space: pre-line;
}
.notification {
  box-shadow: 0px 0px 10px #ddd;
  padding: 22px;
  background-color: #f3f3f3;
  margin-bottom: 10px;
  overflow: auto;
}
.details .owl-item {
  background-color: unset !important;
}
.owl-item {
  background-color: #fff !important;
  padding: 12px 15px 15px;
  color: #333;
}
.services-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.service-search {
  /*margin-bottom: 20px;*/
  /*position: relative;*/
}
.dashboard-search {
  display: none;
  width: 100%;
  background-color: #ddd;
  margin: 0 0px;
  color: #555;
  font-size: 22px;
  box-shadow: 2px 2px 2px #333;
  position: absolute;
  z-index: 2;
}
.dashboard-search .active,
.dashboard-search div:hover {
  background-color: #555;
  color: #eeeeee;
}
.dashboard-search div {
  width: 100% !important;
  padding: 5px 15px;
  cursor: pointer;
}
.service-search .input {
  width: 100% !important;
  font-size: 28px;
  padding-left: 40px;
  color: #777;
  outline: none;
  height: 50px !important;
}
.service-search svg {
  position: absolute;
  margin-top: 15px;
  margin-left: 12px;
  color: #777;
  font-size: 20px;
}
.notificationIcon {
  width: 70px;
  margin: 0 auto;
  padding-top: 10px !important;
}
.iteme-custom {
  cursor: pointer;
  padding-right: 20px;
}

.iteme-custom:hover {
  cursor: pointer;
  color: #ff006e !important;
  border-bottom: 3px solid #ff006e;
}

.searchInput {
  background-color: white !important;
  border: 1px solid #ccc !important;
  height: 4.4rem !important;
  padding-top: 5px;
}
table th {
  font-size: 15px;
}
table {
  margin-top: 20px !important;
}
.close-icon {
  padding: 20px;
  font-size: 35px;
  cursor: pointer;
}
.close-icon :hover {
  color: #003e3e;
}
.icon {
  color: #fff;
  font-size: 32px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
input[disabled]:disabled {
}

.balance-total {
  font-weight: 900 !important;
}
.date {
  box-shadow: unset !important;
}
.flow_intro {
  font-size: 15px;
}
.content {
  overflow-y: scroll;
  height: 60vh;
  white-space: wrap;
  margin-top: 10px;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.button-group {
  width: 145px !important;
  margin: 5px !important;
}
.pagingLength {
  left: 19%;
  position: relative;
  background-color: white !important;
  border: 1px solid #e1e1e1;
  font-size: 15px;
}
.languageHome {
  text-decoration: none;
  padding-right: 30px;
  margin-bottom: 0 !important;
}
.select-languageHome {
  background-color: #fb0;
  border: 0;
  margin-bottom: 0 !important;
  text-align: end;
}
.languageHome li {
  list-style-type: none;
}

.gameTitle {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.wallet-button :hover {
  opacity: 0.5 !important;
}
.cardCustom {
  background-color: #003e3e !important;
  padding-top: 30px;
}
.gameButton {
  width: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.margin-right {
  margin-right: 120px;
}
.margin-left {
  margin-left: 120px;
}
.tdButton {
  color: #003e3e;
  cursor: pointer;
  text-align: right;
}

.tdButton > span {
  padding: 5px;
}
.datePicker {
  background-color: #003e3e !important;
  color: white !important;
  font-size: 15px !important;
}
.text-15 {
  font-size: 15px;
}
.text-20 {
  font-size: 20px;
}
.m-5 {
  margin-left: 3px;
}
.mr-5 {
  margin-right: 5px;
}

.preview::before {
  content: "PREVIEW";
  position: absolute;
  transform: rotate(-55deg);
  font-size: 10rem;
  color: rgb(188, 188, 188);
  display: grid;
  pointer-events: none;
  justify-content: center;
  align-content: center;
  top: 200px;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.3;
}
.basket {
  position: relative;
  left: 10%;
}
.basket-badge {
  margin-left: -16px;
  margin-top: -22px;
}

.basket-icon {
  font-size: 27px;
}
.basket-form {
  background-color: #fff !important;
  opacity: 0.9;
  flex: 0 0 auto;
  margin-top: 0px;
  margin-left: 0px;
  padding: 50px;
  width: 100%;
}

.message {
  /*width: 100%;
  padding: 0 30px;
  padding-bottom: 20px;*/
}
.message-body {
  background-color: #ddd;
  color: #333;
  font-weight: 400;
  display: inline-block;
  padding: 12px;
  /*border-radius: 10px;*/
  /*min-width: 400px;*/
  width: 70%;
  line-height: 20px;
  text-align: initial;
}

.message-infos {
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
  font-size: 13px;
}

.status-body {
  text-align: center;
}

.message:last-child {
  padding-top: 12px;
}
.me {
  text-align: right;
  /*padding: 0 30px;
  padding-bottom: 20px;*/
}

.me > .message-body {
  color: #fff;
  background-color: #003e3e;
}
.chat-input :focus {
  box-shadow: unset !important;
  border-color: unset !important;
}
.date-input {
  height: 40px;
  /* width: 136px; */
}

.transaction-details {
  display: grid;
  grid-template-columns: 40% 30% 30%;
  margin: 80px 32px 80px 32px;
  grid-gap: 20px;
}
.transaction-dashboard-title {
  position: relative;
  left: 15px;
  cursor: pointer;
}
.status-dashboard-card {
  box-shadow: 0px 0px 10px #ddd;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  margin-bottom: 20px;
}

.status-dashboard-card-header {
  display: flex;
  margin-bottom: 20px;
}

.status-dashboard-card-header div {
  flex: 0 0 50%;
  padding: 0 30px;
}

.status-dashboard-card-header div {
  font-size: 26px;
}

.status-dashboard-card-header div:nth-child(1) {
  text-align: start;
}

.status-dashboard-card-header div:nth-child(2) {
  text-align: end;
  font-variant: small-caps;
}

.status-dashboard-card-row {
  display: grid;
  padding: 0px 30px;
}

.status-dashboard-card-label {
  font-size: 18px;
  font-variant: small-caps;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
}

.status-dashboard-card-value {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.status-dashboard {
  display: grid;
  grid-template-columns: calc(33.33% - 15px) calc(33.33% - 15px) calc(
      33.33% - 15px
    );
  gap: 20px;
}

.status-dashboard-back {
  font-size: 25px;
  margin-left: 20px;
  cursor: pointer;
  white-space: nowrap;
}
.status-dashboard-back svg {
  margin-right: 5px;
}

.status-dashboard-card-form {
  /*padding: 0 30px;
  margin-top: 20px;*/
}

.status-dashboard-card-form-label {
  font-size: 14px;
  font-variant: small-caps;
}

.status-dashboard-card-form-row {
  margin-bottom: 10px;
  display: grid;
}

.status-dashboard-card-form-row * {
  width: 100%;
  margin: 0 !important;
}

.status-dashboard-card-form input,
.status-dashboard-card-form select,
.status-dashboard-card-form textarea {
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid #999;
}

.flex {
  display: flex;
  gap: 5px;
}
.flex-center {
  justify-content: center;
}
.flex-20 {
  flex: 0 0 20%;
}
.flex-30 {
  flex: 0 0 30%;
}
.flex-40 {
  flex: 0 0 40%;
}
.flex-50 {
  flex: 0 0 50%;
}
.flex-60 {
  flex: 0 0 60%;
}
.flex-70 {
  flex: 0 0 70%;
}
.flex-80 {
  flex: 0 0 80%;
}
.flex-150px {
  flex: 0 0 150px;
}
.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.circle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.user-preview-icon {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: #ddd;
  color: #222;
  font-size: 55px;
}

.font-caps {
  font-variant: small-caps;
}
.font-small {
  font-size: 14px;
}
.font-medium {
  font-size: 24px;
}
.font-big {
  font-size: 30px;
}
.font-huge {
  font-size: 40px;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.padding20 {
  padding: 20px;
}
.margin20-0 {
  margin: 20px 0;
}
.margin-top-20 {
  margin-top: 20px;
}
.product-card {
  margin: 1rem;
  cursor: pointer;
}

.product-card img {
  max-width: 100%;
  height: auto;
}

.product-card h2 {
  margin-top: 8px;
  font-size: 1.2rem;
}

.product-card p {
  font-size: 1rem;
  margin: 8px 0;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  gap: 10px;
  /* grid-auto-flow: column; */
}

.product-data-container {
  padding: 1rem;
}

.filters {
  display: flex;
  column-gap: 20px;
}

.filter-item {
  width: 300px;
}

.filter-item > select {
  display: block;
  background-color: white;
  color: #555;
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  outline: none;
  border-radius: 0;
  font-size: 18px;
  padding: 0 15px;
  margin-bottom: 20px;
}

.filter-icon {
  margin-top: 30px;
  cursor: pointer;
}

.details-container {
  display: flex;
  /* justify-content: space-between; */
}

.details-content {
  width: 40%;
}

.price-h {
  color: #6d6d6d;
  margin-top: 1rem;
}
.product-img {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.product-img-details {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  /* background-position: center; */
  background-size: contain;
  cursor: pointer;
}
.form-select:focus {
  background-color: unset !important;
  box-shadow: unset !important;
}
.video-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  gap: 10px;
}
.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: floralwhite;
  font-size: xxx-large;
}
