@media only screen and (max-width: 800px) {

    .itm-home-bg {
        background-image: none;
      }

      .itm-home-bg > div {
        padding-top: 80px;
      }
      
      .list {
        padding-top: 20px;
      }

      .status-dashboard-card button, .status-dashboard-card-label {
        font-size: 12px;
      }

}