@media only screen and (max-width: 800px) {
  /*
   * STRUCTURE
   */
  .col {
    flex: unset !important;
  }
  .dashboard-col {
    width: 50% !important;
  }
  .hide-on-mobile {
    display: none;
  }

  /*
   * HEADER
   */
  .iteme-header {
    max-height: 70px !important;
    margin-bottom: 0;
  }
  .iteme-header img {
    height: 50%;
  }

  /*
   * FOOTER
   */
  .iteme-footer .col-2 {
    width: 100%;
  }


  /*
   * DATE PICKER
   */

  .rdrDateRangePickerWrapper {
    /* width: 100vw; */
  }

  .rdrDefinedRangesWrapper {
    position: absolute;
    width: 100% !important;
    top: 344px;
  }

  /*
   * FORM
   */

  .basket-badge {
    margin-left: 11px;
    margin-top: -49px;
  }
  #basket {
    width: 65% !important;
  }
  .form-title .title {
    display: none;
  }
  .iteme-form .row {
    display: unset;
  }
  .iteme-form .col {
    padding: 0 20px;
  }
  .iteme-form .col-2 {
    width: 100%;
    margin-top: 10px;
  }

  /*
   * HOMEPAGE
   */
  .left-line-black,
  .right-line-black {
    width: 0px;
  }

  .left-line-black:before,
  .right-line-black:before {
    bottom: -11px;
  }
  .col-9.text {
    width: 100%;
  }

  /*
    * DASHBOARD
    */
  .dashboard {
    grid-template-columns: auto;
  }
  .services-container {
    grid-template-columns: 50% 50%;
  }
  .status-dashboard {
    grid-template-columns: 100%;
  }
  .dashboard-logo {
    max-width: 80px;
  }
  .status-dashboard-card-header {
    display: unset;
  }
  .status-dashboard-card-header div {
    width: 100%;
  }
  .status-dashboard-card-value {
    overflow-wrap: break-word;
  }
  .dashboard-icon {
    font-size: 40px;
  }

  .message-body {
    width: 90%;
  }
  .details-container {
    flex-wrap: wrap;
  }
  .details-content {
    width: 100%;
  }
  .product-card img {
    width: 100%;
  }
  .filters {
    flex-direction: column;
  }
  .product-list {
    display: block;
  }
}
.register-intro {
  margin-bottom: 30px;
  color: #fff;
  font-size: 20px;
  font-family: "montserrat-regular", sans-serif;
}
.register-link{
  color: #003e3e;
  cursor: pointer;
}