/*

  z-indexes

  overlay                              1000
  go-top                                600
  Homepage-Header:                      501
  ("home-image-right")                  500
  ("about-how-content .step::before")   500
  ("left-line:before")                    2
  ("right-line:before")                   2
  ("left-line-black:before")              2
  ("right-line-black:before")             2

*/


.itm-home-bg {
  background-image: url("../images/RTN_Landing_page.jpg");
}

.itm-home-bg img {
  width: 80%
}


/*********
  GENERAL
 ********/

.itm-pos-rel {
  position: relative;
}

.itm-hidden {
  display: none;
}

/**********
   MODAL
 **********/

.itm-modal {
  background-color: #003030;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-shadow: #000 0 0 20px;
}

.itm-modal img {
  width: 50%;
  margin: 30px 0;
}

.itm-modal h3 {
  margin-bottom: 15px;
}

.itm-modal input:focus {
  background-color: #fff !important;
  color: #000 !important;
}

.itm-pin-input {
  width: 100px !important;
  margin: auto;
  font-size: 26px !important;
  text-align: center;
}


/************
   MESSAGES
 ************/

.itm-error {
  background-color: #f99;
  color: #000;
  padding: 20px;
}

.itm-success {
  background-color: #003030;
  color: #c4f600;
  padding: 20px;
}


/***********
   BUTTONS
 ***********/

.itm-clickable {
  cursor: pointer;
}

.itm-button {
  height: 40px;
  border: 0;
  outline: 0;
}

.itm-x-button {
  position: absolute;
  top: 12px;
  right: 14px;
  border: 1px solid #aaa;
  padding: 0px 8px;
  color: #aaa;
  cursor: pointer;
}

.itm-x-button:hover {
  border: 1px solid #777;
  color: #777;
}


/*********
  COLORS
**********/

.itm-bg-teal-gradient {
  /* background: linear-gradient(to bottom, rgba(0, 46, 46, 0.8), rgba(0, 20, 20, 0.99)); */
  background: linear-gradient(to bottom, rgba(0, 46, 46, 0.91), rgba(0, 20, 20, 0.91));
}

.itm-bg-teal {
  background-color: #004c4c;
}

.itm-bg-blue {
  background-color: #66b2b2;
}

.itm-bg-lime {
  background-color: #c5f700;
}

.itm-bg-white {
  background-color: #ffffff;
}

.itm-white {
  color: #ffffff;
}

.itm-teal {
  color: #004c4c;
}

.itm-blue {
  color: #66b2b2;
}

.itm-lime {
  color: #c5f700;
}

/********
  SPACES
*********/

.h-100 {
  height: 100%;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

/********
  FORMS
 *******/

.itm-form {}

.itm-form input {
  display: block;
  background-color: #fff;
  width: 100%;
  height: 40px;
  border: 0;
  outline: 0;
  padding: 0 10px;
}

.itm-form label {
  margin-bottom: 2px;
}

.itm-submit {
  margin-top: 20px;
  float: right;
}

.itm-next {
  margin-top: 20px;
  float: left;
}